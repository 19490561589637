.videos {
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.videos .filter {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.videos .header {
  margin-bottom: 15px;
}

.videos .video {
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
}

.videos .video .image {
  height: 300px;
}

.videos .video .actions {
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

.videos .video .actions .action {
  display: flex;
  flex-direction: row;
  align-items: center;
}