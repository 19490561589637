.table {
  border-radius: 5px;
}

.table .uploaded {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table .uploaded .link {
  color: #fff;
  text-decoration-color: #fff;
  margin-right: 10px;
}
