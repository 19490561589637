.products {
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.products .header {
  margin-bottom: 15px;
}

.products .product {
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
}

.products .product .image {
  height: 300px;
}

.products .product .actions {
  justify-content: space-between;
}