.categories {
  width: 100%;
  max-width: 500px;
}

.categories .content {
  padding: 20px;
}

.categories .content .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.categories .content .info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0 0 0;
}

.categories .content .info a {
  color: #FFF;
}

.draggable-list {
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
  border: 1px solid #263238;
  border-radius: 3px;
  overflow: hidden;
}

.draggable-category {
  padding: 10px;
  margin: 2px 0;
  background-color: rgba(	51, 53, 66, 0.3);
  cursor: grab;
  list-style: none;
}

.draggable-category:nth-child(odd) {
  background-color: rgba(	51, 53, 66, 0.5);
}

.draggable-category:first-child {
  margin-top: 0;
}

.draggable-category:last-child {
  margin-bottom: 0;
}

.draggable-category:hover {
  background-color: rgba(	51, 53, 66, 0.7);
}

.draggable-category .controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.draggable-category .controls .flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.draggable-category .delete-button {
  color: #FFF;
}

.draggable-category .code-input {
  margin: 0 0 0 5px;
  padding: 5px 10px;
  background: #333542;
  border-radius: 4px;
  border: none;
  outline: none;
  width: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 13px;
  color: #FFF;
}