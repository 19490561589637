.login, .login form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login form button {
  margin-top: 30px;
  width: 100%;
}