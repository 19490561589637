@import url(https://fonts.googleapis.com/css?family=Nunito);
body {
  margin: 0;
  background-color: #424452;
}
.login, .login form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login form button {
  margin-top: 30px;
  width: 100%;
}
.products {
  align-items: center;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.products .header {
  margin-bottom: 15px;
}

.products .product {
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
}

.products .product .image {
  height: 300px;
}

.products .product .actions {
  justify-content: space-between;
}
.users {
  align-items: center;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.users .header {
  margin-bottom: 15px;
}

.users .user {
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
}

.users .user .image {
  height: 300px;
}

.users .user .actions {
  justify-content: space-between;
}

.users .user .email {
  color: #fff;
  -webkit-text-decoration-color: #fff;
          text-decoration-color: #fff;
  font-size: 13px;
  font-family: Avenir, Nunito, BlinkMacSystemFont, "Segoe UI";
}
.videos {
  align-items: center;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.videos .filter {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.videos .header {
  margin-bottom: 15px;
}

.videos .video {
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
}

.videos .video .image {
  height: 300px;
}

.videos .video .actions {
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1;
}

.videos .video .actions .action {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table {
  border-radius: 5px;
}

.table .uploaded {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table .uploaded .link {
  color: #fff;
  -webkit-text-decoration-color: #fff;
          text-decoration-color: #fff;
  margin-right: 10px;
}

.create-videos {
  display: flex;
  align-items: center;
  overflow: auto;
}

.create-products {
  display: flex;
  align-items: center;
  overflow: auto;
}

.create-products {
  display: flex;
  align-items: center;
  overflow: auto;
}

.categories {
  width: 100%;
  max-width: 500px;
}

.categories .content {
  padding: 20px;
}

.categories .content .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.categories .content .info {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 0 0 0;
}

.categories .content .info a {
  color: #FFF;
}

.draggable-list {
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
  border: 1px solid #263238;
  border-radius: 3px;
  overflow: hidden;
}

.draggable-category {
  padding: 10px;
  margin: 2px 0;
  background-color: rgba(	51, 53, 66, 0.3);
  cursor: -webkit-grab;
  cursor: grab;
  list-style: none;
}

.draggable-category:nth-child(odd) {
  background-color: rgba(	51, 53, 66, 0.5);
}

.draggable-category:first-child {
  margin-top: 0;
}

.draggable-category:last-child {
  margin-bottom: 0;
}

.draggable-category:hover {
  background-color: rgba(	51, 53, 66, 0.7);
}

.draggable-category .controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.draggable-category .controls .flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1;
}

.draggable-category .delete-button {
  color: #FFF;
}

.draggable-category .code-input {
  margin: 0 0 0 5px;
  padding: 5px 10px;
  background: #333542;
  border-radius: 4px;
  border: none;
  outline: none;
  width: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 13px;
  color: #FFF;
}
.center {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.page-content {
  padding: 20px 15px;
  display: flex;
  justify-content: center;
}
