.users {
  align-items: center;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.users .header {
  margin-bottom: 15px;
}

.users .user {
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
}

.users .user .image {
  height: 300px;
}

.users .user .actions {
  justify-content: space-between;
}

.users .user .email {
  color: #fff;
  text-decoration-color: #fff;
  font-size: 13px;
  font-family: Avenir, Nunito, BlinkMacSystemFont, "Segoe UI";
}