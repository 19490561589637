.center {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.page-content {
  padding: 20px 15px;
  display: flex;
  justify-content: center;
}